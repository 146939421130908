import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SearchResult from '../components/mini/searchResult'
import SearchInput from '../components/mini/searchInput'
import { useQueryParam, StringParam } from 'use-query-params'

const PageTemplate = ({ pageContext, page, location }) => {
  const [searchQuery, setSearchQuery] = useQueryParam('q', StringParam)
/*
  useEffect(() => {
    setSearchQuery(location?.state?.searchQuery)
  }, [location])
  */
  return (
    <Layout
      template={pageContext.template}
      language={pageContext.language}
      page={{ slug: pageContext?.slug, uri: pageContext?.uri }}
    >
      <Seo
        title={pageContext?.title}
      />
      <div>
        <section className="content text-center">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className=" col-12 col-md-10 col-lg-8">
                <div className="row">
                  <div className="col-12 title">
                    <h1>Sökresultat</h1>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-6 page-search-wrap">
                      <SearchInput
                        onSearchClick={(searchQuery) => {
                          setSearchQuery(searchQuery)
                        }}
                        initialQuery={searchQuery}
                      />
                    </div>
                  </div>
                  <div className="col-12 main-content columns-1">
                    <SearchResult searchQuery={searchQuery} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default PageTemplate