import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

const Loading = ({ hits }) => (
  <>
    {[...Array(Math.min(20, Math.max(hits, 1)))].map((e, i) => (
      <div className="ph-item" key={`search`+i}>
        <div className="ph-col-12 p-0">
          <div className="ph-row m-0">
            <div className="ph-col-4"></div>
            <div className="ph-col-12 empty"></div>
            <div className="ph-col-6 big"></div>
          </div>
        </div>
      </div>
    ))}
  </>
)


const Component = ({ searchQuery }) => {
  const [lang, setLang] = useState('sv')
  const [data, setData] = useState({ total: 0, results: [], lang: lang })
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState({ s: searchQuery, lang: lang })
  const objToQueryString = (obj) => {
    const keyValuePairs = []
    for (const key in obj) {
      keyValuePairs.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
      )
    }
    return keyValuePairs.join('&')
  }

  useEffect(() => {
    setQuery(q => ({...q, s: searchQuery}))
  }, [searchQuery])


  useEffect(() => {
    if (query?.s?.length == 0) {
      return false
    }
    // set loading
    setLoading(true)
    // get data api
    const rootURL =
      process.env.WPAPI_URL || 'https://admin.orebrokonserthus.com/wp-json/'
    fetch(
      `${rootURL}core/v1/search/?${objToQueryString(
        query
      )}`
    )
      .then((response) => response.json()) // parse JSON from request
      .then((resultData) => {
        setData(resultData)
        setLoading(false)
      })
  }, [query])

  return (
    <div className="search-results-wrap">
      {!!loading && <Loading hits={data.total} />}
      {!loading && !data.total && <h3 className="text-left text-md-center">Inga resultat på din sökning</h3>}
      {!loading &&
        !!data.total &&
        data.results.map((item) => (
          <div className="result" key={item.id}>
            <h4 className="subTitle">
              <strong>{item.subTitle}</strong>
            </h4>
            <Link to={item.url}>
              <h3>{item.title}</h3>
            </Link>
          </div>
        ))}
    </div>
  )}

export default Component
